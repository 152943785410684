<div matRipple class="saved-search" [matMenuTriggerFor]="savedSearchMenu" [ngClass]="{'saved-search--active' : searchContainer.focus.curFocusId}">
  <mat-icon class="saved-search__icon-leading">
    saved_search
  </mat-icon>

  <span class="saved-search__label">
    <span class="saved-search__button-label">
      @if (searchContainer.focus.curFocusId) {
        {{ searchContainer.focus.curFocus.focus.name }}
      }
      @else {
        {{ 'TRANS__FILTER_MENU__SAVED_SEARCH' | translate }}
      }
    </span>
  </span>

  @if (searchContainer.focus.curFocusId) {
    <button mat-icon-button (click)="clearSetFocus($event)" [matTooltip]="'TRANS__FILTER_MENU__CLEAR_SET_FOCUS' | translate" class="density--2">
      <mat-icon>
        clear
      </mat-icon>
    </button>
  }

  <mat-icon class="saved-search__button-icon">
  arrow_drop_down
  </mat-icon>

  <mat-menu #savedSearchMenu="matMenu" class="saved-search__menu mat-elevation-z18">
    <div class="saved-search__menu-dropdown" (click)="$event.stopPropagation()">
      <div class="saved-search__dropdown-button-container">
        @if(activeFocusHasChanges()) {
          <button mat-flat-button color="primary" class="saved-search__dropdown-button">
            {{ 'TRANS__FILTER_MENU__SAVED_SEARCH__SAVE_CHANGES' | translate }}
          </button>
          <button mat-stroked-button class="saved-search__dropdown-button" (click)="createFocusFromSearch($event)">
            {{ 'TRANS__FILTER_MENU__SAVED_SEARCH__SAVE_ACTIVE' | translate }}
          </button>
        }
        @else {
          <button mat-flat-button color="primary" class="saved-search__dropdown-button" (click)="createFocusFromSearch($event)">
            {{ 'TRANS__FILTER_MENU__SAVED_SEARCH__SAVE_ACTIVE' | translate }}
          </button>
        }
      </div>

      <div class="saved-search__dropdown-list-container">
        <div class="saved-search__header-container">
          <span class="saved-search__header">
            {{ 'TRANS__FILTER_MENU__SAVED_SEARCH' | translate }}
          </span>

          <mat-icon class="saved-search__header-icon" [matTooltip]="'TRANS__FILTER_MENU__TOOLTIP_SAVED_SEARCH' | translate">
            help_outline
          </mat-icon>
        </div>

        <div class="saved-search__list-container">
          @if (displayList.length === 0) {
            <div class="saved-search__empty-state">
              {{ 'TRANS__FILTER_MENU__NO_SAVED_SEARCH' | translate }}
            </div>
          }

          <mat-action-list class="saved-search__list density--2">
            @for (focus of displayList; track $index) {
              @if ($index <= currentMaxListLength - 1) {
                <button mat-list-item (click)="setFocus(focus, $event)">
                  <div class="saved-search__list-item">
                    <mat-icon class="saved-search__list-item-icon">
                      saved_search
                    </mat-icon>

                    <span class="saved-search__list-item-text">
                    {{ focus.focus.name }}
                  </span>

                    <button mat-icon-button class="saved-search__list-item-button density--4" (click)="itemActionButtonClick($event)" [matMenuTriggerFor]="focusMenu">
                      <mat-icon>
                        more_vert
                      </mat-icon>
                    </button>

                    <mat-menu #focusMenu="matMenu">
                      <!-- TODO: Fix one-shot search from focus -->
                      <!--<button mat-menu-item (click)="setSearchFromFocus(focus)">
                        {{ 'TRANS__FILTER_MENU__SEARCH_FROM_FOCUS' | translate }}
                      </button>

                      <button mat-menu-item (click)="setFocus(focus, $event)">
                        {{ 'TRANS__FILTER_MENU__SET_FOCUS' | translate }}
                      </button>-->

                      <button mat-menu-item (click)="editFocus($event, focus)">
                        {{ 'TRANS__FILTER_MENU__EDIT_FOCUS' | translate }}
                      </button>

                      <button mat-menu-item (click)="deleteFocus($event, focus)">
                        {{ 'TRANS__FILTER_MENU__DELETE_FOCUS' | translate }}
                      </button>
                    </mat-menu>
                  </div>
                </button>
              }
            }
          </mat-action-list>
        </div>

        @if (displayList.length > currentMaxListLength) {
          <button mat-button (click)="showMoreOfList($event)">
            <div class="saved-search__show-more-button">
              <mat-icon>
                more_horiz
              </mat-icon>

              <span class="saved-search__show-more-label">
                {{ 'TRANS__FILTER_MENU__SAVED_SEARCH_SHOW_MORE' | translate }}
              </span>
            </div>
          </button>
        }

        <div>
          <div class="saved-search__header-container">
            <span class="saved-search__header">
              {{ 'TRANS__FILTER_MENU__STARTING_SEARCH' | translate }}
            </span>

            <mat-icon class="saved-search__header-icon" [matTooltip]="'TRANS__FILTER_MENU__TOOLTIP_STARTING_SEARCH' | translate">
              help_outline
            </mat-icon>
          </div>

          <div class="saved-search__focus-container" [ngClass]="{'saved-search__focus-container--active': searchContainer.focus.curFocusId}">
            <mat-icon>
              keep
            </mat-icon>

              @if (searchContainer.focus.curFocusId) {
                <div class="saved-search__focus-label"> {{ searchContainer.focus.curFocus.focus.name }} </div>
              }
              @else {
                <div class="saved-search__focus-label saved-search__focus-label--empty-state"> {{ 'TRANS__FILTER_MENU__NO_FOCUS' | translate }} </div>
              }

            @if (searchContainer.focus.curFocusId) {
              <button mat-icon-button class="density--4" (click)="clearSetFocus($event)" [matTooltip]="'TRANS__FILTER_MENU__CLEAR_SET_FOCUS' | translate">
                <mat-icon class="saved-search__focus-container-icon">
                  clear
                </mat-icon>
              </button>


            }
          </div>

          @if (searchContainer.focus.curFocusId) {
            <div class="saved-search__checkbox-container">
              <mat-checkbox class="saved-search__checkbox" (click)="checkboxClicked($event)" [checked]="searchContainer.focus.curFocus.focus.isDefault">
                <span class="saved-search__checkbox-label">
                  {{ 'TRANS__FILTER_MENU__KEEP_FOCUS' | translate }}
                </span>
              </mat-checkbox>


            </div>
          }
        </div>
      </div>
    </div>
  </mat-menu>
</div>
